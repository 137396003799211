<template>
  <v-container
    id="create-supplier"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <v-form
          method="post"
          ref="supplier"
          @submit.prevent="validate"
        >
          <base-material-card
            color="primary"
          >
            <template v-slot:heading>
              <div
                v-if="!$route.params.id"
                class="display-2 font-weight-light"
              >
                Ajouter un nouveau sous-traitant
              </div>
              <div
                v-if="$route.params.id"
                class="display-2 font-weight-light"
              >
                Mettre à jour le sous-traitant
              </div>
            </template>
              <v-container class="py-0">
                <v-row>
                  <v-col
                    cols="12"
                    md="7"
                  >
                    <v-text-field
                      outlined
                      label="Raison sociale *"
                      v-model="supplier.raisonSociale"
                      :rules="[v => !!v || 'Indiquez la raison sociale']"
                      required
                      @change="formEdited = true"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                  >
                    <v-autocomplete
                      outlined
                      label="Forme juridique"
                      v-model="supplier.formeJuridique"
                      :items="['SARL', 'SAS', 'Auto-entrepreneur']"
                      clearable
                      @change="formEdited = true"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      outlined
                      label="E-mail"
                      type="email"
                      v-model="supplier.email"
                      @change="formEdited = true"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      outlined
                      label="Téléphone"
                      type="tel"
                      v-model="supplier.telephone"
                      @change="formEdited = true"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      outlined
                      label="SIREN"
                      type="number"
                      v-model="supplier.siren"
                      @blur="updateSiret()"
                      @change="formEdited = true"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      outlined
                      label="SIRET"
                      type="number"
                      v-model="supplier.siret"
                      @change="formEdited = true"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                  >
                    <v-textarea
                      outlined
                      label="Adresse"
                      v-model="supplier.adresse.adressePostale"
                      @change="formEdited = true"
                    />
                  </v-col>

                  <v-col
                    cols="6"
                    md="4"
                  >
                    <v-text-field
                      outlined
                      label="Code postal"
                      v-model="supplier.adresse.codePostal"
                      @change="formEdited = true"
                    />
                  </v-col>

                  <v-col
                    cols="6"
                    md="4"
                  >
                    <v-text-field
                      outlined
                      label="Ville"
                      v-model="supplier.adresse.ville"
                      @change="formEdited = true"
                    />
                  </v-col>

                  <v-col
                    cols="6"
                    md="4"
                  >
                    <v-text-field
                      outlined
                      label="Pays"
                      v-model="supplier.adresse.pays"
                      @change="formEdited = true"
                    />
                  </v-col>

                  <v-col
                  cols="12"
                  md="6"
                >
                  <v-file-input
                    outlined
                    v-model="attestationVigilance"
                    accept=".pdf"
                    label="Attestation de vigilance URSSAF"
                    prepend-icon="mdi-file-account"
                    hint="Format accepté : PDF"
                    persistent-hint
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-file-input
                    outlined
                    v-model="kbis"
                    accept=".pdf"
                    label="Extrait KBIS"
                    prepend-icon="mdi-file-account"
                    hint="Format accepté : PDF"
                    persistent-hint
                    @change="formEdited = true"
                  />
                </v-col>
                </v-row>
              </v-container>
            </base-material-card>
            <v-row>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  v-if="!$route.params.id"
                  color="primary"
                  class="mr-0"
                  type="submit"
                  :disabled="createUpdateDisabled"
                >
                  Ajouter le sous-traitant
                </v-btn>
                <v-btn
                  v-if="$route.params.id"
                  color="primary"
                  class="mr-0"
                  type="submit"
                  @click="updateSupplier()"
                  :disabled="createUpdateDisabled"
                >
                  Mettre à jour le sous-traitant
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    data() {
      return {
        entreprisesSousTraitantes: [],
        supplier: {
          raisonSociale: '',
          formeJuridique: '',
          email: '',
          telephone: '',
          iban: '',
          swift: '',
          tvaIntra: '',
          siren: '',
          siret: '',
          adresse: {},
        },
        attestationVigilance: null,
        kbis: null,
        logo: null,
        createUpdateDisabled: false,
      }
    },

    beforeMount() {
      window.addEventListener('beforeunload', this.preventNav);
    },

    beforeDestroy() {
      window.addEventListener('beforeunload', this.preventNav);
    },

    beforeRouteLeave(to, from, next) {
      if (this.formEdited) {
        if (!window.confirm('Les modifications que vous avez apportées seront perdues, souhaitez-vous quitter la page ?')) {
          return;
        }
      }
      next();
    },

    created() {
      if (this.$route.params.id) {
        // edit mode
        this.axios.get('/entreprisesSousTraitantes/' + this.$route.params.id)
          .then((res) => {
            this.supplier = res.data;
            this.supplier.adresse = this.supplier.adresse ?? {};
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la récupération du sous-traitant', type: 'warn' });
          });
      }
    },

    methods: {
      validate () {
        this.formEdited = false;
        if (this.$refs.supplier.validate()) {
          if (this.$route.params.id) {
            // edit mode
            this.updateSupplier();
          } else {
            // create mode
            this.createSupplier();
          }
        } else {
          this.setSnack({ text: 'Veuillez renseigner tous les champs obligatoires', type: 'warn' });
        }
      },

      createSupplier() {
        this.createUpdateDisabled = true;

        let formData;

        this.axios.post('/entreprisesSousTraitantes', this.supplier)
          .then((res) => {
            if (this.attestationVigilance) {
              formData = new FormData();
              formData.append('file', this.attestationVigilance);
              this.axios.patch('/entreprisesSousTraitantes/' + res.data.id + '/uploadAttestationVigilance', formData, { headers: { 'content-type': 'multipart/form-data' } });
            }
            return res;
          })
          .then((res) => {
            if (this.kbis) {
              formData = new FormData();
              formData.append('file', this.kbis);
              this.axios.patch('/entreprisesSousTraitantes/' + res.data.id + '/uploadKbis', formData, { headers: { 'content-type': 'multipart/form-data' } });
            }
            return res;
          })
          .then((res) => {
            this.createUpdateDisabled = false;
            this.$router.push({ name: 'SousTraitantDetail', params: { id: res.data.id } })
            this.setSnack({ text: 'Le sous-traitant a bien été ajouté', type: 'success' });
          })
          .catch(e => {
            this.createUpdateDisabled = false;
            this.setSnack({ text: 'Erreur lors de l\'ajout du nouveau sous-traitant', type: 'warn' });
          });
      },

      updateSupplier() {
        this.createUpdateDisabled = true;

        let formData;
        this.axios.put('/entreprisesSousTraitantes/' + this.supplier.id, this.supplier)
          .then((res) => {
            if (this.attestationVigilance) {
              formData = new FormData();
              formData.append('file', this.attestationVigilance);
              this.axios.patch('/entreprisesSousTraitantes/' + res.data.id + '/uploadAttestationVigilance', formData, { headers: { 'content-type': 'multipart/form-data' } });
            }
            return res;
          })
          .then((res) => {
            if (this.kbis) {
              formData = new FormData();
              formData.append('file', this.kbis);
              this.axios.patch('/entreprisesSousTraitantes/' + res.data.id + '/uploadKbis', formData, { headers: { 'content-type': 'multipart/form-data' } });
            }
            return res;
          })
          .then((res) => {
            this.createUpdateDisabled = false;
            this.setSnack({ text: 'Le sous-traitant a bien été mis à jour', type: 'success' });
            this.$router.push({ name: 'SousTraitantDetail', params: { id: this.supplier.id } });
          })
          .catch(e => {
            this.createUpdateDisabled = false;
            this.setSnack({ text: 'Erreur lors de la mise à jour du sous-traitant', type: 'warn' });
          });
      },

      updateSiret() {
        this.supplier.siret = this.supplier.siren;
        this.$forceUpdate();
      },

      preventNav(event) {
        if (this.formEdited) {
          event.preventDefault();
          event.returnValue = '';
          this.formEdited = false;
        }
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }
</script>
